import { useAuth0 } from "@auth0/auth0-react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import {
  ForzaEventArgs,
  ForzaEventsParameters,
  MAX_PLAYLIST_SEGMENTS,
} from "api";
import theme from "app/theme";
import { EventThumbnail } from "components";
import { AddEventsToPlaylistModal, AddEventToPlaylistModal } from "containers";
import {
  ForzaEvent,
  ForzaEvents,
  ForzaEventType,
  UserRole,
} from "generated/openapi";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AppState,
  selectCurrentUserRole,
  selectEventsFilters,
  selectOrg,
  setAlert,
  useAppDispatch,
  useAppSelector,
} from "store";

interface Props {
  action: (
    query: ForzaEventsParameters,
  ) => AsyncThunkAction<ForzaEvents, any, any>;
  selector: (state: AppState) => ForzaEvents;
  eventType: ForzaEventType;
}

export const EventsContainer: FC<Props> = (props) => {
  const { action, selector, eventType } = props;
  const { isAuthenticated } = useAuth0();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));
  const dispatch = useAppDispatch();
  const org = useAppSelector(selectOrg);
  const userRole = useAppSelector(selectCurrentUserRole);
  const { values, total } = useAppSelector(selector);
  const filters = useAppSelector(selectEventsFilters);
  const [limit, _setLimit] = useState<number>(12);
  const [offset, setOffset] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<ForzaEvent | undefined>(
    undefined,
  );
  const [selectedEventsQuery, setSelectedEventsQuery] = useState<
    ForzaEventArgs | undefined
  >(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);

  useEffect(() => {
    dispatch(action({ ...filters, limit, offset }));
  }, [filters, limit, offset]);

  // Don't display empty categories
  if (!total) {
    return <></>;
  }
  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Card
          variant="elevation"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "primary.main",
            color: "white",
            borderRadius: 2,
            p: 2,
            px: mobileBreakpoint ? 8 : 1,
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {mobileBreakpoint && <Grid item sm={3} />}
            <Grid item xs={8} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: mobileBreakpoint ? "center" : "flex-start",
                  py: 1,
                }}
              >
                <Typography sx={{ textAlign: "center" }} variant="h4">
                  {eventType} [{total}]
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} sm={3} sx={{ textAlign: "right" }}>
              {isAuthenticated &&
                total <= MAX_PLAYLIST_SEGMENTS &&
                userRole !== UserRole.Player && (
                  <Button
                    sx={{ gridColumn: 3, px: 2 }}
                    color="inherit"
                    startIcon={<PlaylistAddIcon />}
                    onClick={() => {
                      setSelectedEventsQuery({
                        eventType,
                        params: {
                          ...filters,
                          limit: MAX_PLAYLIST_SEGMENTS,
                          offset: 0,
                        },
                      });
                      setModalOpen(true);
                    }}
                  >
                    {mobileBreakpoint ? "Add To Playlist" : "Add"}
                  </Button>
                )}
            </Grid>
            {values.map((e, i) => (
              <Grid item xs={12} md={6} lg={4} xl={3} xxl={3} key={i}>
                <Box
                  sx={{
                    width: "100%",
                    position: "relative",
                    paddingTop: "56.25%",
                  }}
                >
                  <Box sx={{ position: "absolute", inset: 0 }}>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/forza/events/${e.id}`}
                    >
                      <EventThumbnail event={e} width="100%" height="100%">
                        {isAuthenticated &&
                          org &&
                          userRole !== UserRole.Player && (
                            <>
                              <IconButton
                                color="inherit"
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  borderRadius: 0,
                                  borderBottomLeftRadius: 6,
                                  bgcolor: "RGBA(0,0,0,0.8)",
                                  "&:hover": { bgcolor: "black" },
                                }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setAnchorEl(event.currentTarget);
                                  setSelectedEvent(e);
                                }}
                              >
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu
                                disableScrollLock
                                anchorEl={anchorEl}
                                open={selectedEvent?.id === e.id && !!anchorEl}
                                onClose={() => setAnchorEl(undefined)}
                                onClick={(event) => event.preventDefault()}
                              >
                                <MenuList disablePadding>
                                  <MenuItem onClick={() => setModalOpen(true)}>
                                    <ListItemIcon>
                                      <PlaylistAddIcon />
                                    </ListItemIcon>
                                    <ListItemText>Add To Playlist</ListItemText>
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </>
                          )}
                      </EventThumbnail>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Pagination
            sx={{ mt: 2 }}
            size={mobileBreakpoint ? "medium" : "small"}
            count={Math.ceil(total / limit)}
            onChange={(_event, page: number) => setOffset((page - 1) * limit)}
            color="secondary"
          />
        </Card>
      </Box>

      {isAuthenticated && selectedEvent && (
        <AddEventToPlaylistModal
          open={modalOpen}
          forzaEvent={selectedEvent}
          onClose={(message) => {
            message &&
              dispatch(
                setAlert({
                  message,
                  severity: "success",
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                }),
              );
            setModalOpen(false);
            setSelectedEvent(undefined);
            setAnchorEl(undefined);
          }}
        />
      )}
      {isAuthenticated && selectedEventsQuery && (
        <AddEventsToPlaylistModal
          open={modalOpen}
          eventsQuery={{ ...selectedEventsQuery, total }}
          onClose={(message) => {
            message &&
              dispatch(
                setAlert({
                  message,
                  severity: "success",
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                }),
              );
            setModalOpen(false);
            setSelectedEvent(undefined);
            setAnchorEl(undefined);
          }}
        />
      )}
    </>
  );
};
